import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"



const IndexPage = () => {
    const root = "/img/download/power-hour"

    return (
        <Layout pageTitle="Power Hour &amp; Summit - Templates">
            <Row>
                <Col>
                    <Link to="/events/power-hour">Back</Link>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
            <h1>Power Hour</h1>
            </Col> </Row>
                
            <ImageDownload
            root ="/img/download/power-hour"
            filename="power-hour-promo"
            name="Power Hour Promo Template"
            text="Use this template to create promos for your Power Hour events"
            text2=""
            squareHref="https://www.canva.com/design/DAFqeeq_JIc/asnqJS1OjXAUxdib6qXldg/view?utm_content=DAFqeeq_JIc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFqe8Z5Q7w/VXwkhRPr_okDociC-0wzVA/view?utm_content=DAFqe8Z5Q7w&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1P1coUOYwsvjQanqrPiOWSgjilO7_4ydiW4fq_OgsHBw/edit?usp=sharing"
            />  
           
           <ImageDownload
            root ="/img/download/power-hour"
            filename="power-hour-promo-2"
            name="Power Hour Promo Template 2"
            text="Use this template to create promos for your Power Hour events"
            text2=""
            squareHref="https://www.canva.com/design/DAFf6CT2OtU/W69kzriz-3pdW8RWtMjVfw/view?utm_content=DAFf6CT2OtU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFf6N5c948/VsovJQ2qm9WHHkpw7Q0exQ/view?utm_content=DAFf6N5c948&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1P1coUOYwsvjQanqrPiOWSgjilO7_4ydiW4fq_OgsHBw/edit?usp=sharing"
            />  

<Row className="mt-5">
                <Col>
            <h1>Summit</h1>
            </Col> </Row>

            <ImageDownload
            root ="/img/download/power-hour"
            filename="summit-promo"
            name="Summit Promo"
            text="Use this template to create event promos for your Summit event"
            text2=""
            squareHref="https://www.canva.com/design/DAFqez5HJh8/n0GoF7UXU_7DZiItshMdRA/view?utm_content=DAFqez5HJh8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFqe-Lzps4/PN2H6eDXjEhLRY5K9zCOAQ/view?utm_content=DAFqe-Lzps4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref=""
            />  

            <ImageDownload
            root ="/img/download/power-hour"
            filename="summit-speaker-promo"
            name="Summit Speaker Promo"
            text="Use this template to create event promos for your Summit Speakers"
            text2=""
            squareHref="https://www.canva.com/design/DAFqelrMO7U/J0oYK6Nan6wpzsgM9u4iJg/view?utm_content=DAFqelrMO7U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFqevhw0pI/HTC0t7QaDy_IhZ_lSh9QIQ/view?utm_content=DAFqevhw0pI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref=""
            />  
            
        </Layout>

        
    )
}

export default IndexPage
